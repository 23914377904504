import React from "react";
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Phone from "../../assets/images/phone.png";
import Email from "../../assets/images/email.png";
import Location from "../../assets/images/location.png";
import "./Contact.css";

const Contact = () => {

    const form = useRef();
    const [status, setStatus] = useState(undefined);

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showIsIncomplete, setIncomplete] = useState(false);
  
    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }

  
    const checkSubmit = (e) => {
        e.preventDefault();
      if (!isValidEmail(email) || name.length < 3 || subject.length == 0 || message.length == 0) {
        setIncomplete(true)
      } else {
        setIncomplete(false)
        sendEmail(e);
      }
    };

  const sendEmail = (e) => {
    e.preventDefault();
    if(status?.type != 'success'){
    emailjs.sendForm('service_we5qvfk', 'template_r6ajiig', form.current, 'lUFqjHP2fEVVtA6YI')
      .then((result) => {
          console.log(result.text);
          setStatus({ type: 'success' });
      }, (error) => {
          console.log(error.text);
          setStatus({ type: 'error', error });
      });
    }
  };

    return (
        <div className="contact">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 contact_section">
                        <h1>CONTACT US</h1>

                        <div className="contact_info">
                            {/* <p> <img src={Phone} alt="Phone" /> +1 213 709 8447</p> */}
                            <p><a href="mailto:melis@elasticwaves.io"> <img src={Email} alt="Phone" /></a> melis@elasticwaves.io</p>
                            <p> <img src={Location} alt="Phone" /> Istanbul, Turkey</p>
                        </div>

                        <div style={{ width: '100%' }}><iframe title={'map'} style={{ width: "100%", height: "350px", border: 'none', borderRadius: '10px', frameborder: "0", scrolling: "no", marginheight: "0", marginwidth: "0" }} src="https://maps.google.com/maps?q=Istanbul,%20Turkey&t=&z=13&ie=UTF8&iwloc=&output=embed"><a href="https://www.gps.ie/wearable-gps/">wearable gps</a></iframe></div>
                    </div>
                    <div className="col-md-6" style={{ marginTop: '40px', padding: '0px' }} data-aos="zoom-in-up">
                        <div className="container-fluid">
                        <form ref={form} onSubmit={checkSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Enter Name*</label>
                                            <input type="text" className="form-control" name="contact_name" onChange={e=>{setName(e.target.value);}} placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email*</label>
                                            <input type="text" className="form-control" name="contact_email" onChange={e=>{setEmail(e.target.value);}} placeholder="Enter Email Address" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input type="text" className="form-control" name="contact_phone" placeholder="Enter Phone Number" />
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" className="form-control" name="contact_address" placeholder="Enter Your Address" />
                                        </div>
                                    </div> */}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Subject*</label>
                                            <input type="text" className="form-control" name="contact_subject" onChange={e=>{setSubject(e.target.value);}}placeholder="Type the Subject" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Message*</label>
                                            <textarea className="form-control" name="contact_message" onChange={e=>{setMessage(e.target.value);}} placeholder="Write your Message Here" rows="5" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {status?.type === 'success' && <p style={{ color: 'white' }}>Message Sent!</p>}
                                        {status?.type === 'error' && (
                                            <p style={{ color: 'red' }}>An error occured, please try again later.</p>
                                        )}
                                        </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="d-flex justify-content-end">
                                            {/* <button className="get_touch_btn">Send Message</button> */}
                                            <input type="submit" className = "get_touch_btn" value="Send"/>
                                        </div>
                                        { showIsIncomplete ? <div class="fill_out">Fill out all required(*) fields</div> : null }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact