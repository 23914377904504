import React from "react";
import Header from "../../Components/Header/Header";
import Welcome from "../Welcome/Welcome";
import Bio from "../Bio/Bio";
import Contact from "../Contact/Contact";
import Footer from "../../Components/Footer/Footer";
import InstaIcon from "../../assets/images/instagram_icon.png";
import emailIcon from "../../assets/images/email_icon.png";
import './Home.css';
import { Element } from 'react-scroll'
import Points from "../Points/Points";
import FooterBee from "../FooterBee/FooterBee";
import Collection from "../Collection/Collection";
import Abundance from "../Abundance/Abundance";

const Home = () => {

    return (
        <React.Fragment>
            <div className='top_header'>
                <div className='container'>
                <p>
                        <span> <a href="https://www.instagram.com/elasticwavesnft/"><img src={InstaIcon} alt='insta' className='insta' /></a></span>
                        |
                        <span> <a href="mailto:melis@elasticwaves.io"><img src={emailIcon} alt='email' className='mx-2 email' /></a></span>
                        melis@elasticwaves.io
                    </p>
                </div>
            </div>
            <div>
                <Header />
                <Element id='home' name='home'>
                    <Welcome />
                </Element>
                <Element id='bio' name='bio'>
                    <Bio />
                </Element>
                <Collection />

                <Abundance />

                <Points id='benefits' name ='benefits'/>
                <FooterBee />

                <Element id='contact' name='contact'>
                    <Contact />
                </Element>
                <Footer />
            </div>
        </React.Fragment>
    )
}
export default Home;