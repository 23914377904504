import React, { useEffect, useState } from "react";
import Home from "./Container/Home/Home";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const App = () => {
    const [loaded, setLoaded] = useState(false);

    // const [startLoad, setStartLoad] = useState(false)

    const [timer, setTimer] = useState(1000)

    document.onreadystatechange = () => {
        setLoaded(document.readyState === "complete");

    }

    // useEffect(() => {
    //     if (!startLoad) {
    //         document.body.classList.add('lock');
    //         setStartLoad(true)
    //     }
    // }, [startLoad])

    useEffect(() => {
        if (loaded) {
            setTimer(0);
            // document.body.classList.remove('lock');
            // ScrollTrigger.clearScrollMemory();
            // window.history.scrollRestoration = "manual"
        }

    }, [loaded])

    useEffect(() => {

        const timeline = gsap.timeline();

        timeline.to(".loader", {
            delay: 0,
            duration: timer,
            opacity: 0,
            display: 'none'
        });
        timeline.to(".animation", {
            delay: 0,
            duration: 1,
            y: "100%",
            ease: "power4.out",
            // height: '100%'
        });
        timeline.to(".animation", {
            zIndex: -1,
            opacity: 0,
            visibility: "hidden",
            height: 0,
            width: 0,
        });

    }, [timer])

    return (
        <div className="App">
            {/* <div className="animation">
                <div className="loader"></div>
            </div> */}
            <div className="home">
                <Home />
            </div>
        </div>

    )
}

export default App;