import React from 'react';
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from "../../assets/images/main_logo.png";
import * as Scroll from 'react-scroll';
import InstaIcon from "../../assets/images/instagram_icon.png";
import emailIcon from "../../assets/images/email_icon.png";
import './Header.css';
let Link = Scroll.Link;

const Header = () => {

    return (
        <header className="header-section is-sticky">
            <div className='top_header mob'>
                <div className='container'>
                    <p>
                        <span><img src={InstaIcon} alt='insta' className='insta' /></span>
                        |
                        <a href='mailto:melis@elasticwaves.io'></a><span><img src={emailIcon} alt='email' className='mx-2 email' /></span>
                        melis@elasticwaves.io
                    </p>
                </div>
            </div>
            <Navbar expand="lg">
                <Container style={{ width: '90%' }}>
                    <Navbar.Brand href="#home"><img src={Logo} alt='logo' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                        <Nav className="mr-auto">
                            <Link
                                to="home"
                                spy={true}
                                smooth={true}
                                duration={500}
                                className={'nav-link'}
                                activeClass='nav-link'
                            >
                                Home
                            </Link>
                            <Link
                                to="bio"
                                spy={true}
                                smooth={true}
                                duration={500}
                                className={'nav-link'}
                                activeClass='nav-link'
                            >
                                Bio
                            </Link>
                            <Link
                                to="benefits"
                                spy={true}
                                smooth={true}
                                duration={500}
                                className={'nav-link'}
                                activeClass='nav-link'
                            >
                                Benefits
                            </Link>
                            <Link
                                to="contact"
                                spy={true}
                                smooth={true}
                                duration={500}
                                className={'nav-link'}
                                activeClass='nav-link'
                            >
                                Contact
                            </Link>
                            <a href="https://xfunds.trade" target="_blank" rel="noreferrer">
                        <button href="xfunds.trade" className='get_touch_btn2'>X-Funds</button>
                            </a>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
export default Header;